<template lang="pug">
  .wc-loaders-infinite-no-more.text-center
    .mt-5.mb-4
      .info
        i.fal.fa-fw.fa-smile-wink.text-primarylight
        |  {{ $t('shared.lists.no_more') }}
</template>

<script>
export default {
  name: 'wc-loaders-infinite-no-more',
}
</script>
