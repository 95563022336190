import ClientOnly from 'vue-client-only'
import InfiniteLoading from 'vue-infinite-loading'
import WcLoadersCardList from '@components/shared/loaders/WcLoadersCardList'
import { notify } from '@common/notifications/dispatch'
import { alertDestroy } from '@common/alerts/types'

const List = {
  mixins: [notify, alertDestroy],

  props: {
    action: String,
    wns: {
      type: String,
      default: 'o',
    },
  },

  components: {
    ClientOnly,
    InfiniteLoading,
    WcLoadersCardList,
  },

  methods: {
    routeParams() {
      return {
        show: [],
        edit: [],
      }
    },
    apiParams(item = {}) {
      return {
        list: [{}, { [`${this.wns}[page]`]: this.page }],
        destroy: [{ id: this.$getDeep(item, 'attributes.sid') }],
      }
    },
    apiRequestBuilder(action, item = {}, paction = null) {
      return this.apiBase[action](this.$http, ...this.apiParams(item)[paction ? paction : action])
    },
    infiniteHandler($state) {
      if (this.pagination.totalPages !== null && this.page === this.pagination.totalPages + 1) {
        $state.complete()
        return
      }
      this.isLoading = true
      this.apiRequest = this.apiRequestBuilder(this.apiRequestFetch)
      this.apiRequest.promise
        .then((response) => {
          if (response.data.length) {
            this.page += 1
            this.directionInverse
              ? this.items.unshift(...response.data.reverse())
              : this.items.push(...response.data)
            if (response.included) this.included.push(...response.included)
            this.pagination = { ...this.pagination, ...this.$getDeep(response.meta, 'pagination') }
            $state.loaded()
          } else {
            $state.complete()
          }
          this.policies = { ...this.policies, ...this.$getDeep(response.meta, 'policies') }
          if (!this.skipNotifications) this.notifyDispatch(response)
          if (!this.skipPolicies) this.handlePolicies()
          this.apiCallback('fetch-success', response)
          this.isLoading = false
          return true
        })
        .catch((response) => {
          this.policies = { ...this.policies, ...this.$getDeep(response.meta, 'policies') }
          if (!this.skipNotifications) this.notifyDispatch(response)
          if (!this.skipPolicies) this.handlePolicies()
          this.apiCallback('fetch-error', response)
          this.isLoading = false
        })
      this.apiCallback('fetch')
    },
    infiniteHandlerReset() {
      this.page = 1
      this.items = []
      this.infiniteId += 1
    },
    itemDelete(item, index) {
      if (!this.actionDestroy) return
      return this.fireDestroy().then((result) => {
        if (result.value) {
          this.$mergeDeep(this.items[index], true, 'isLoading')
          this.apiRequest = this.apiRequestBuilder('destroy', item)
          this.apiRequest.promise // eslint-disable-line promise/no-nesting
            .then((response) => {
              if (this.$getDeep(response, 'meta.destroyed.soft')) {
                this.$mergeDeep(this.items[index], response.data)
                this.$mergeDeep(this.items[index], false, 'isLoading')
              } else {
                /* Re-test if always exists. Websocket might have already deleted the item. */
                const index = this.items.findIndex(
                  (i) => i.id === response.data.id && i.type === response.data.type
                )
                if (index !== -1) this.items.splice(index, 1)
              }
              if (!this.skipNotifications) this.notifyDispatch(response)
              this.apiCallback('item-delete-success', response)
              return true
            })
            .catch((response) => {
              if (!this.skipNotifications) this.notifyDispatch(response)
              this.$mergeDeep(this.items[index], false, 'isLoading')
              this.apiCallback('item-delete-error', response)
            })
          this.apiCallback('item-delete', item)
        } else {
          this.fireDestroyCancel()
        }
        return true
      })
    },
    itemClicked(item) {
      if (
        this.actionItemClicked &&
        (this.skipPolicies || this.$getDeep(item, 'attributes.policies.show'))
      )
        this.$router.push(...this.routeParams(item)[this.actionItemClicked])
    },
    itemDataIncluded(type, id) {
      if (this.included) {
        return this.included.find((v) => v.type === type && v.id === id)
      }
    },
    itemDataIncludedMany(item, type, id) {
      if (!item || !this.included) return []
      return item.reduce((acc, rv) => {
        acc.push(this.included.find((v) => v.type === rv[type] && v.id === rv[id]))
        return acc
      }, [])
    },
    handlePolicies() {
      this.actionDestroy = this.policies.destroy
      this.actionEdit = this.policies.edit
    },
    onCancel() {
      this.apiRequest.source.cancel()
    },
    apiCallback(callback) {
      return callback
    },
    searchParamsQuery(params, query = {}) {
      return Object.entries({
        ...query,
        ...params,
      }).reduce((a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }), {})
    },
  },
  data() {
    return {
      isLoading: false,
      apiBase: null,
      apiRequest: null,
      apiRequestFetch: 'list',
      actionDestroy: false,
      actionEdit: false,
      actionItemClicked: null,
      skipNotifications: false,
      skipPolicies: false,
      infiniteId: +new Date(),
      directionInverse: false,
      page: 1,
      pagination: {
        totalItems: null,
        totalPages: null,
      },
      items: [],
      included: [],
      policies: {},
    }
  },
}

export default List
