<template lang="pug">
  b-card-group(:columns='columns')
    template(v-for='item in items')
      b-card.wc-loaders-card-list.card-white.card-no-gutters.shadow(no-body)
        b-card-body
          wc-loaders-heading-avatar(align='center')
</template>

<script>
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'

export default {
  name: 'wc-loaders-card-list',
  components: {
    WcLoadersHeadingAvatar,
  },
  props: {
    columns: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Number,
      default: 5,
    },
  },
}
</script>
