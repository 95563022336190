<template lang="pug">
  .wc-loaders-infinite-no-results.text-center
    .mt-5.mb-4
      i.fal.fa-fw.fa-grin-beam-sweat.fa-5x.text-primarylight

    .info
      | {{ $t('shared.lists.no_results') }}
      br
      | {{ $t('shared.lists.retry') }}
</template>

<script>
export default {
  name: 'wc-loaders-infinite-no-results',
}
</script>
